<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update Funded
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="NgosFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules=""
              >
                <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
                <quill-editor
                  ref="NgosFormEditor"
                  v-model="NgosForm.description"
                  :options="getEditorOptions('NgosFormEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Title"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="NgosForm.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Slug"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="slug"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="NgosForm.slug"
                  @input="removeSpace($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Excerpt"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Excerpt"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-Excerpt"
                  v-model="NgosForm.excerpt"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class=""
          >
            <div
              class="border rounded p-2"
              style="margin:0px"
            >
              <h4 class="mb-1">
                image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewE2"
                    :src="`https://lpcentre.com/${NgosForm.image}`"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-media-body>
                  <b-card-text class="my-50" />
                  <div class="d-inline-block">
                    <b-form-group
                      label=" image"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="NgosForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Image Caption"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="NgosForm.image_caption"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="faq Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="faq"
                  rules=""
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model="NgosForm.faq_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="faq description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Details"
                  rules=""
                >
                  <quill-editor
                    ref="NgosFormFaqEditor"
                    v-model="NgosForm.faq_description"
                    :options="getEditorOptions('NgosFormFaqEditor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Courses Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="courses"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model="NgosForm.courses_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Courses description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Details"
                  rules=""
                >
                  <quill-editor
                    ref="NgosFormCoursesEditor"
                    v-model="NgosForm.courses_description"
                    :options="getEditorOptions('NgosFormCoursesEditor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Other Courses Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="other_courses_title"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model="NgosForm.other_courses_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Other Courses description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="other_courses_description"
                  rules=""
                >
                  <quill-editor
                    ref="NgosFormOtherCoursesEditor"
                    v-model="NgosForm.other_courses_description"
                    :options="getEditorOptions('NgosFormOtherCoursesEditor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Diploma Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="diploma_title"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model="NgosForm.long_courses_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Diploma description"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Details"
                  rules=""
                >
                  <quill-editor
                    ref="NgosFormLongCoursesEditor"
                    v-model="NgosForm.long_courses_description"
                    :options="getEditorOptions('NgosFormLongCoursesEditor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Meta Title"
                label-for="blog-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="meta_title"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-title"
                    v-model="NgosForm.meta_title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Meta Description"
                label-for="blog-edit-slug"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="meta_description"
                  rules="required"
                >
                  <b-form-input
                    id="blog-edit-slug"
                    v-model="NgosForm.meta_description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Meta Keywords"
                label-for="blog-content"
                class="mb-2"
              >
                <b-form-tags v-model="NgosForm.meta_keywords" />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                v-if="!isLoading"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="save"
              >
                Save Changes
              </b-button>
              <b-button
                v-if="isLoading"
                variant="primary"
                disabled
                class="mr-1"
              >
                Loading...
                <b-spinner small />
                <span class="sr-only" />
              </b-button>
            </b-col>
          </b-row>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import vSelect from "vue-select";
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const { route } = useRouter();
    const isLoading = ref(false);
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refPreviewE2 = ref(null);
    const VisitorList = ref([]);
    const Tabimage = ref('media/svg/files/blank-image.svg');

    const form = ref({
      description: '',
      title: '',
      meta_title: '',
      meta_description: '',
      meta_keywords: '',
      keywords: '',
      image: '',
      image_caption: '',
      excerpt:'',
      slug:'',
      faq_title:'',
      faq_description:'',
      courses_title:'',
      courses_description:'',
      long_courses_title:'',
      long_courses_description:'',
      other_courses_title:'',
      other_courses_description:'',
    });

    const NgosForm = reactive(form);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64;
      },
    );

    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64;
      },
    );

    const { id } = route.value.params;
    const NgosFormvalidate = ref();

    store.dispatch('setting/getNgos', { id }).then(response => {
      form.value = response.data.data;
      form.value.meta_keywords = response.page_content.meta_keywords.split(',');
    });

    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }

    const linkHandler = editorRef => {
      const range = proxy.$refs[editorRef].quill.getSelection();
      if (range) {
        const url = prompt('Enter the URL');
        if (url) {
          const target = confirm('Open in new tab?') ? '_blank' : '_self';
          const linkValue = `<a href="${url}" target="${target}">${proxy.$refs[editorRef].quill.getText(range)}</a>`;
          proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue);
        }
      }
    };

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
        
            ['bold', 'italic', 'underline', 'strike'],
            ['clean'],
            ['undo', 'redo'],
            ['paragraph'],

          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef),
          },
        },
      },
    });

    const save = () => {
      const formData = new FormData();

      NgosFormvalidate.value.validate().then(success => {
        if (success) {
          isLoading.value = true;
          formData.append('description', NgosForm.value.description);
          formData.append('meta_title', NgosForm.value.meta_title);
          formData.append('title', NgosForm.value.title);
          formData.append('slug', NgosForm.value.slug);
          formData.append('excerpt', NgosForm.value.excerpt);
          formData.append('meta_description', NgosForm.value.meta_description);
          formData.append('image', NgosForm.value.image);
          formData.append('meta_keywords', NgosForm.value.meta_keywords);
          formData.append('image_caption', NgosForm.value.image_caption);
          formData.append('_method', 'put');
          formData.append('faq_title', NgosForm.value.faq_title);
          formData.append('other_courses_title', NgosForm.value.other_courses_title);
          formData.append('other_courses_description', NgosForm.value.other_courses_description);
          formData.append('faq_description', NgosForm.value.faq_description);
          formData.append('courses_title', NgosForm.value.courses_title);
          formData.append('courses_description', NgosForm.value.courses_description);
          formData.append('long_courses_title', NgosForm.value.long_courses_title);
          formData.append('long_courses_description', NgosForm.value.long_courses_description);

          store.dispatch('setting/updateNgos', { id, formData })
            .then(response => {
              isLoading.value = false;
              Vue.swal({
                title: 'Updated',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };

    const removeSpace = (target) => {
      NgosForm.value.slug = target.toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/&/g, ``)
        .replace(/--/g, `-`)
        .replace(/[.,]/g, `-`)
        .replace(/[_]/g, ``)
        .replace(/[!''""=]/g, ``)
        .replace(/[!?%$^~*#()+]/g, ``);
    };

    return {
      isLoading,
      removeSpace,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      NgosForm,
      inputImageRenderer,
      NgosFormvalidate,
      VisitorList,
      form,
      id,
      save,
      required,
      email,
      getEditorOptions,
       imageHandler,
      insertImage,
      imageProperties,
    };
  },

  components: {
    BCard,
    vSelect,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
